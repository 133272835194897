<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("drugbook", ["fetchDrugbookIncorrectBalanceURL"]),
    async getDrugbookIncorrectBalanceURL() {
      try {
        const res = await this.fetchDrugbookIncorrectBalanceURL();
        if (res.status == 200) {
          window.location.href = res.data.data;
        }
      } catch (error) {
        console.log("ERR: ", error);
      }
    },
  },
  async created() {
    await this.getDrugbookIncorrectBalanceURL();
  },
};
</script>

<style></style>
